import styles from "./Login.module.css";
import logo from "../images/logo.png";
import { MdEmail, MdLock } from "react-icons/md";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

function extractUserName(email) {
  const indexAt = email.indexOf("@");
  return email.substring(0, indexAt);
}

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const changeEmailHandler = (e) => {
    setEmail(e.target.value);
  };

  const changePasswordHandler = (e) => {
    setPassword(e.target.value);
  };

  const login = (e) => {
    e.preventDefault();
    setLoading(true);

    const authenticationData = {
      Username: extractUserName(email),
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: extractUserName(email),
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        console.log("access token: " + result.getAccessToken().getJwtToken());
        setLoading(false);
        navigate("/dashboard");
      },
      onFailure: (err) => {
        alert(err);
        setLoading(false);
      },
    });

    // console.log(email);
    // console.log(password);
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <img src={logo} alt="Hero" className={styles.logo} />
        <h1 className={styles.title}>Ingreso</h1>
        <form onSubmit={login} className={styles.frm}>
          <div className={styles.email}>
            <MdEmail className={styles.icon} />
            <input
              type="email"
              placeholder="usuario@correo.com"
              autoFocus
              onChange={changeEmailHandler}
              required
              className={styles.input}
            />
          </div>
          <div className={styles.email}>
            <MdLock className={styles.icon} />
            <input
              type="password"
              placeholder="**********"
              onChange={changePasswordHandler}
              required
              className={styles.input}
            />
          </div>
          <button className={styles.button} type="submit">
            Ingresar
          </button>
          {loading && <progress value={null} className={styles.progress} />}
          <div className={styles.options}>
            <Link to="/auth/register" className={styles.link}>
              Crear una cuenta
            </Link>
            <Link to="/auth/forgotPassword" className={styles.link}>
              Olvidé mi clave
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
